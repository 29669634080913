<template>
  <v-navigation-drawer
    v-if="data"
    :key="data.uuid"
    v-model="open"
    :width="$vuetify.breakpoint.mobile ? '100%' : 350"
    app
    xclipped
    right
    temporary
    class="elevation-8"
  >
    <v-card-title>
      <div class="text-h6 py-1">
        <v-icon left>
          fal fa-user-md
        </v-icon>
        {{ data?.uuid ? 'Edit' : 'Add' }} Provider
      </div>
      <v-spacer />
      <v-btn
        icon
        tile
        right
        color="grey"
        @click.stop="$emit('close-drawer')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-container class="spy-0">
      <v-form
        ref="providerForm"
        v-model="valid"
        @submit.prevent="updateProvider"
      >
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="data.prefix"
              :readonly="(!$auth.check({ providers: 'edit' }) && data.uuid) || (!$auth.check({ providers: 'add' }) && !data.uuid)"
              :items="prefixes"
              label="Prefix"
              dense
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="data.first_name"
              :readonly="(!$auth.check({ providers: 'edit' }) && data.uuid) || (!$auth.check({ providers: 'add' }) && !data.uuid)"
              :label="$t('first name') | capitalize"
              dense
              :rules="rules.first_name"
            />
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="data.last_name"
              :readonly="(!$auth.check({ providers: 'edit' }) && data.uuid) || (!$auth.check({ providers: 'add' }) && !data.uuid)"
              :label="$t('last name') | capitalize"
              dense
              :rules="rules.last_name"
            />
          </v-col>

          <v-col
            cols="12"
          >
            <v-text-field
              v-model.number="data.npi"
              :readonly="(!$auth.check({ providers: 'edit' }) && data.uuid) || (!$auth.check({ providers: 'add' }) && !data.uuid)"
              label="NPI"
              type="number"
              dense
              :rules="rules.npi"
            />
          </v-col>

          <v-col
            cols="12"
          >
            <v-text-field
              v-model="data.email"
              prepend-icon="fal fa-at"
              :label="$t('email')"
              type="email"
              dense
              :rules="rules.email"
            />
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model.number="data.phone"
              prepend-icon="fal fa-phone"
              :label="$t('phone') | capitalize"
              type="tel"
              dense
            />
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model.number="data.fax"
              prepend-icon="fal fa-fax"
              :label="$t('fax') | capitalize"
              type="tel"
              dense
            />
          </v-col>

          <v-col
            v-if="$auth.user().client && $auth.user().client.services.map(x => x.name).includes('Precision ASP')"
            cols="12"
            class="py-0"
          >
            <v-switch
              v-model="data.all_meta.asp_sms_alerts"
              label="Receive Precision ASP Alerts"
              prepend-icon="fal fa-mobile-signal-out"
              dense
            />
          </v-col>

          <v-col
            cols="12"
          >
            <v-text-field
              v-model="data.address"
              :label="$t('address') | capitalize"
              dense
            />
          </v-col>

          <v-col
            cols="12"
            md="7"
          >
            <v-text-field
              v-model="data.city"
              :label="$t('city') | capitalize"
              dense
            />
          </v-col>

          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              v-model="data.state"
              :label="$t('state') | capitalize"
              dense
            />
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="data.zip"
              :label="$t('postcode')"
              dense
            />
          </v-col>

          <v-divider />
          <v-col
            v-if="$auth.user().client?.services.filter(x => x.name.includes('OneChoice Reports'))"
            cols="12"
            class="py-0"
          >
            <v-switch
              v-if="!$auth.user().client.reports_all_matrix"
              v-model="data.matrix"
              :readonly="false"
              label="Include MedsMatrix on Reports"
              prepend-icon="fal fa-grid"
              dense
            />
            <v-switch
              v-else
              v-model="data.no_matrix"
              :readonly="false"
              label="Disable MedsMatrix on Reports"
              prepend-icon="fak fa-light-grid-slash"
              dense
            />
          </v-col>

          <v-col
            v-if="$auth.check({ clients: 'edit' })"
            cols="12"
          >
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="formattedAdvancedAspDate"
                  label="Advanced ASP"
                  prepend-icon="fal fa-calendar"
                  readonly
                  dense
                  hint="Select start date"
                  clearable
                  v-bind="attrs"
                  v-on="on"
                >
                  <template #prepend>
                    <v-icon size="20">
                      fal fa-calendar-day
                    </v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                v-model="data.advanced_asp"
                no-title
                scrollable
                reactive
                @input="menu = false"
              />
            </v-menu>
            <!-- <v-date-picker
              xv-model="data.advanced_asp"
              label="Advanced ASP"
              dense
            /> -->
          </v-col>

          <v-col
            v-if="$auth.check({ clients: 'edit' })"
            cols="12"
          >
            <tag-picker
              :value.sync="data.tags"
              type="provider"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <template #append>
      <v-col class="pb-0">
        <v-btn
          :loading="loading"
          type="submit"
          color="success"
          block
          @click="updateProvider"
        >
          {{ data?.uuid ? 'Update' : 'Add' }} Provider
        </v-btn>
      </v-col>
      <v-col
        cols="12"
      >
        <v-btn
          v-if="data.uuid && $auth.check({ providers: 'edit' })"
          color="error"
          block
          outlined
          @click="deleteProvider"
        >
          <v-icon
            small
            left
          >
            fal fa-trash
          </v-icon>
          Delete Provider
        </v-btn>
      </v-col>
    </template>
  </v-navigation-drawer>
</template>
<script>
  export default {
    components: {
      TagPicker: () => import('@/components/forms/TagPicker.vue'),
    },
    props: {
      open: Boolean,
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data: () => ({
      valid: false,
      loading: false,
      prefixes: [
        'Dr.',
        'Prof.',
        'Mr.',
        'Mrs.',
        'Ms.',
        'Miss',
      ],
      menu: null,
      rules: {
        first_name: [
          v => !!v || this.$t('required'),
        ],
        last_name: [
          v => !!v || this.$t('required'),
        ],
        // email: [
        //   v => /^$|.+@.+/.test(v) || 'Please enter a valid e-mail address',
        // ],
        // npi: [
        //   v => !!v || this.$t('required'),
        // ],
      },
    }),
    computed: {
      formattedAdvancedAspDate () {
        return this.data.advanced_asp ? this.$moment(this.data.advanced_asp).format('LL') : null
      },
    },
    methods: {
      updateProvider () {
        if (this.valid) {
          this.loading = true
          const formData = new FormData()
          for (const [key, value] of Object.entries(this.data)) {
            formData.append(key, value)
          }
          if (this.data.photo && typeof this.data.photo === 'object') {
            formData.append('files', this.data.photo, this.data.photo.name)
          }
          if (this.data.uuid) {
            formData.append('_method', 'patch')
          }
          this.axios.request({
            method: this.data.uuid ? 'patch' : 'post',
            url: this.data.uuid ? 'admin/providers/' + this.data.uuid : 'admin/providers',
            data: this.data,
          })
            .then(() => {
              this.$root.$emit('fetch-providers')
              this.$emit('close-drawer')
              this.$toast.success('Provider updated')
            })
            .catch(error => {
              this.$toast.error(error)
            })
            .finally(() => {
              this.loading = false
            })
        }
      },
      deleteProvider () {
        this.$dialog.warning({
          text: 'Do you really want to delete this provider?',
          title: 'Delete Provider',
          icon: 'fas fa-exclamation-triangle mr-2',
          actions: {
            true: {
              text: 'Yes', color: 'red',
            },
            false: 'No',
          },
        })
          .then((res) => {
            if (res) {
              this.axios.delete(`admin/providers/${this.data.uuid}`)
                .then(() => {
                  this.$root.$emit('fetch-providers')
                  this.$toast.success('Provider deleted')
                })
                .catch(error => {
                  this.$toast.error(error)
                })
            }
          })
      },
    },
  }
</script>
