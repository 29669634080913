<template>
  <v-navigation-drawer
    v-if="data"
    :key="data.uuid"
    v-model="open"
    :width="$vuetify.breakpoint.mobile ? '100%' : 350"
    app
    xclipped
    right
    temporary
    class="elevation-8"
  >
    <v-card-title>
      <div class="text-h6 py-1">
        <v-icon left>
          fal fa-hospital-wide
        </v-icon>
        {{ data?.uuid ? 'Edit' : 'Create' }} Facility
      </div>
      <v-spacer />
      <v-btn
        icon
        tile
        right
        color="grey"
        @click.stop="$emit('close-drawer')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-container class="spy-0">
      <v-form
        ref="facilityForm"
        v-model="valid"
        @submit.prevent="updateFacility"
      >
        <v-row>
          <v-col
            cols="12"
          >
            <v-text-field
              v-model="data.name"
              :label="$t('name') | capitalize"
              dense
            />
          </v-col>

          <v-col
            cols="12"
          >
            <v-text-field
              v-model="data.address"
              :label="$t('address') | capitalize"
              dense
            />
          </v-col>

          <v-col
            cols="12"
            md="7"
          >
            <v-text-field
              v-model="data.city"
              :label="$t('city') | capitalize"
              dense
            />
          </v-col>

          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              v-model="data.state"
              :label="$t('state') | capitalize"
              dense
            />
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="data.zip"
              :label="$t('postcode')"
              dense
            />
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="data.phone"
              :label="$t('phone') | capitalize"
              type="tel"
              dense
            >
              <template #prepend>
                <v-icon size="20">
                  fal fa-phone-alt mt-1
                </v-icon>
              </template>
            </v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="data.fax"
              :label="$t('fax') | capitalize"
              type="tel"
              dense
            >
              <template #prepend>
                <v-icon size="20">
                  fal fa-fax mt-1
                </v-icon>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12">
            <tag-picker
              :value.sync="data.tags"
              type="facility"
            />
          </v-col>

          <v-col cols="12">
            <formulary-picker
              :value.sync="data.formulary"
            />
          </v-col>

          <v-col cols="12">
            <v-file-input
              v-model="data.logo_file"
              prepend-icon="fal fa-image"
              label="Report Logo"
              accept="image/*"
              dense
              show-size
            />
            <v-img
              v-if="typeof data.logo === 'string'"
              :src="data.logo"
              contain
            />
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <template #append>
      <v-divider />
      <v-col class="pb-0">
        <v-btn
          :loading="loading"
          type="submit"
          color="success"
          block
          @click="updateFacility"
        >
          {{ data?.uuid ? 'Update' : 'Create' }} Facility
        </v-btn>
      </v-col>
      <v-col
        cols="12"
      >
        <v-btn
          v-if="data.uuid && $auth.check({ facilities: 'edit' })"
          color="error"
          block
          outlined
          @click="deleteFacility"
        >
          <v-icon
            small
            left
          >
            fal fa-trash
          </v-icon>
          Delete Facility
        </v-btn>
      </v-col>
    </template>
  </v-navigation-drawer>
</template>
<script>
  export default {
  components: {
    TagPicker: () => import("@/components/forms/TagPicker.vue"),
    FormularyPicker: () => import("@/components/forms/FormularyPicker.vue"),
  },
    props: {
      open: Boolean,
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data: () => ({
      valid: false,
      loading: false,
      tagLoading: false,
      tagSearch: null,
      tags: [],
      rules: {
        name: [
          v => !!v || this.$t('required'),
        ],
      },
    }),
    methods: {
      updateFacility () {
        if (this.valid) {
          this.loading = true
          const formData = new FormData()
          for (const [key, value] of Object.entries(this.data)) {
            if (value) {
              if (value instanceof Object || value instanceof Array) {
                formData.append(key, JSON.stringify(value))
              } else {
                formData.append(key, value)
              }
            }
          }
          if (this.data.logo && typeof this.data.logo === 'object') {
            formData.append('logo', this.data.logo, this.data.logo.name)
          }
          if (this.data.uuid) {
            formData.append('_method', 'patch')
          }
          this.axios.request({
            method: 'post',
            url: this.data.uuid ? 'admin/facilities/' + this.data.uuid : 'admin/facilities',
            data: formData,
          })
            .then(() => {
              this.$root.$emit('fetch-facilities')
              this.$emit('close-drawer')
              this.$toast.success('Facility updated')
            })
            .catch(error => {
              this.$toast.error(error)
            })
            .finally(() => {
              this.loading = false
            })
        }
      },
      deleteFacility () {
        this.$dialog.warning({
          text: 'Do you really want to delete this facility?',
          title: 'Delete Facility',
          icon: 'fas fa-exclamation-triangle mr-2',
          actions: {
            true: {
              text: 'Yes', color: 'red',
            },
            false: 'No',
          },
        })
          .then((res) => {
            if (res) {
              this.axios.delete('admin/facilities/' + this.data.uuid)
                .then(() => {
                  this.$root.$emit('fetch-facilities')
                  this.$toast.success('Facility deleted')
                })
                .catch(error => {
                  this.$toast.error(error)
                })
            }
          })
      },
    },
  }
</script>
